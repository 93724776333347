import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

type Props = {
  children: React.ReactElement
  initClassName?: string
  revealClassName?: string
}

const ScrollReveal = (props: Props) => {
  const initClassName = props.initClassName || 'fadein'
  const revalClassName = props.revealClassName || 'scrollin'

  const { ref, inView } = useInView({ triggerOnce: true })
  const [classNames, setClassNames] = useState([initClassName])

  useEffect(() => {
    setTimeout(() => {
      if (inView) {
        setClassNames((prev) => [...prev, revalClassName])
      }
    }, 300)
  }, [inView])

  return (
    <div className={classNames.join(' ')} ref={ref}>
      {props.children}
    </div>
  )
}

export default ScrollReveal
