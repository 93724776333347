const Footer = () => {
  return (
    <footer id="gFooter">
      <div className="fInner clearfix">
        <div className="fLogo">
          <a href="https://top-group.jp">
            <img
              src="https://top-group.jp/web/wp-content/themes/TOP/img/common/f_logo.png"
              alt="TOP GROUP"
            />
          </a>
        </div>
        <ul className="fNavi clearfix">
          <li>
            <ul>
              <li>
                <a href="https://top-group.jp/message">CEO メッセージ</a>
              </li>
              <li>
                <a href="https://top-group.jp/group">グループ情報</a>
              </li>
              <li>
                <a href="https://top-group.jp/career">キャリア支援＆教育制度</a>
              </li>
              <li>
                <a
                  href="https://www.baitoru.com/op325773/"
                  target="_blank"
                  rel="noreferrer"
                >
                  採⽤情報
                </a>
              </li>
              <li>
                <a href="https://top-group.jp/news">最新情報</a>
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <a href="https://top-group.jp/staff">
                  ティーオーピー・スタッフ
                </a>
              </li>
              <li>
                <a href="https://top-group.jp/staff-service">業務内容</a>
              </li>
              <li>
                <a href="https://top-group.jp/staff-flow">ご利⽤の流れ</a>
              </li>
              <li>
                <a href="https://top-group.jp/staff-contact">お問い合わせ</a>
              </li>
              <li>
                <a href="https://top-group.jp/staff-entry">
                  お仕事エントリーフォーム
                </a>
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <a href="https://top-group.jp/progress">
                  ティーオーピー・プログレス
                </a>
              </li>
              <li>
                <a href="https://top-group.jp/progress-service">業務内容</a>
              </li>
              <li>
                <a href="https://top-group.jp/progress-flow">ご利⽤の流れ</a>
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li>
                <a href="https://top-group.jp/vietnam">
                  ティーオーピー・ベトナム
                </a>
              </li>
              <li className="liStyle">
                <a href="https://top-group.jp/vietnam-service">業務内容</a>
              </li>
            </ul>
          </li>
          <li>
            <ul>
              <li className="liStyle">
                <a href="https://top-group.jp/logi">ティーオーピー・ロジ</a>
              </li>
              <li>
                <a href="https://top-group.jp/contact">総合受付</a>
              </li>
              <li>
                <a href="https://top-group.jp/sitepolicy">サイトポリシー</a>
              </li>
              <li>
                <a href="https://top-group.jp/privacypolicy">
                  プライバシーポリシー
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p className="copyright en">&copy; TOP GROUP</p>
    </footer>
  )
}

export default Footer
