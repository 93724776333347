import clsx from 'clsx'

type Props = {
  isFix: boolean
  onSPToggleClick: () => void
}

const Header = (props: Props) => {
  return (
    <header id="gHeader" className={clsx({ fix: props.isFix })}>
      <div className="hInner clearfix">
        <div className="lBox">
          <h1 className="logo">
            <a href="https://top-group.jp">
              <img
                src="https://top-group.jp/web/wp-content/themes/TOP/img/common/logo.jpg"
                alt="TOP GROUP"
              />
            </a>
          </h1>
          <div className="menu sp">
            <span onClick={props.onSPToggleClick}>
              <img
                src="https://top-group.jp/web/wp-content/themes/TOP/img/common/sp_menu.png"
                width="28"
                alt="menu"
              />
            </span>
          </div>
          <nav id="gNavi">
            <ul className="clearfix">
              <li>
                <a href="https://top-group.jp/message">CEOメッセージ</a>
              </li>
              <li>
                <a href="https://top-group.jp/group">グループ情報</a>
              </li>
              <li>
                <a
                  href="https://www.baitoru.com/op325773/"
                  target="_blank"
                  rel="noreferrer"
                >
                  採⽤情報
                </a>
              </li>
              <li>
                <a href="https://top-group.jp/news">最新情報</a>
              </li>
              <li>
                <a href="https://top-group.jp/contact">総合受付</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="rBox">
          <ul className="listUl pc clearfix">
            <li>
              <a href="https://top-group.jp/personal">
                <img
                  src="https://top-group.jp/web/wp-content/themes/TOP/img/common/h_icon01.png"
                  width="64"
                  alt="お仕事をお探しの方へ"
                />
                <span className="txt">お仕事をお探しの方へ</span>
              </a>
            </li>
            <li>
              <a href="https://top-group.jp/company">
                <img
                  src="https://top-group.jp/web/wp-content/themes/TOP/img/common/h_icon02.png"
                  width="64"
                  alt="企業のご担当者様へ"
                />
                <span className="txt">企業のご担当者様へ</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
