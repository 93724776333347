import clsx from 'clsx'
import * as style from './SPMenu.module.scss'

type Props = {
  isShow: boolean
  onSPToggleClick: () => void
}

const SPMenu = (props: Props) => {
  const classNames = clsx({
    spBox: true,
    [style.spMenu]: true,
    [style.spMenuActive]: props.isShow,
  })

  return (
    <div className={classNames} style={{ display: 'block' }}>
      <div className="spMenu">
        <span onClick={props.onSPToggleClick}>
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/common/sp_menu.png"
            width="28"
            alt="menu"
          />
        </span>
      </div>
      <ul className="spNavi clearfix">
        <li>
          <a href="https://top-group.jp/message">CEOメッセージ</a>
        </li>
        <li>
          <a href="https://top-group.jp/group">グループ情報</a>
        </li>
        <li>
          <a
            href="https://www.baitoru.com/op325773/"
            target="_blank"
            rel="noreferrer"
          >
            採⽤情報
          </a>
        </li>
        <li>
          <a href="https://top-group.jp/news">最新情報</a>
        </li>
        <li>
          <a href="https://top-group.jp/contact">総合受付</a>
        </li>
      </ul>
    </div>
  )
}

export default SPMenu
