import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import '@/assets/styles/index.css'
import '@/assets/styles/style.css'
import '@/assets/styles/slick.css'
import '@/assets/styles/ovverride.css'
import WPMeta from './WPMeta'
import Header from '@/components/orgs/Header'
import SPMenu from '@/components/orgs/SPMenu'
import Tel from '@/components/orgs/Tel'
import Footer from '@/components/orgs/Footer'
import SPNavbar from '@/components/orgs/SPNavbar'
import ScrollReveal from '@/components/atoms/ScrollReveal'

type Props = {
  children: React.ReactNode
  mainContent?: React.ReactNode
}

const MainTemplate = (props: Props) => {
  const [isShowSPMenu, setIsShowSPMenu] = useState(false)

  const { ref, inView } = useInView({
    rootMargin: '200px 0px 0px 0px',
  })

  const handleOnSPToggleClick = () => {
    setIsShowSPMenu(!isShowSPMenu)
  }

  return (
    <>
      <WPMeta />
      <div id="container">
        <Header isFix={!inView} onSPToggleClick={handleOnSPToggleClick} />
        {/**
         *  Headerをfixされるかどうかを判定するための要素
         */}
        <div ref={ref}></div>
        <SPMenu isShow={isShowSPMenu} onSPToggleClick={handleOnSPToggleClick} />
        <section id="main">{props.children}</section>
        <ScrollReveal>
          <Tel />
        </ScrollReveal>
        <Footer />
        <SPNavbar />
      </div>
    </>
  )
}

export default MainTemplate
