const SPNavbar = () => {
  return (
    <ul className="comFixed sp clearfix">
      <li className="tel">
        <a href="tel:0120886662">
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/common/sp_icon01.png"
            width="13"
            alt="電話"
          />
          電話
        </a>
      </li>
      <li className="access">
        <a
          href="https://goo.gl/maps/tE6WujnodgHLfKQk9"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fas fa-map-marker-alt"></i>地図
        </a>
      </li>
      <li className="contact">
        <a href="https://top-group.jp/contact">
          <img
            src="https://top-group.jp/web/wp-content/themes/TOP/img/common/sp_icon02.png"
            width="17"
            alt="メールでのご相談"
          />
          メールでのご相談
        </a>
      </li>
    </ul>
  )
}

export default SPNavbar
