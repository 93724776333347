import { Helmet } from 'react-helmet'

const WPMeta = () => {
  return (
    <Helmet>
      <meta
        name="description"
        content="人材紹介・人材派遣・通関サポート・商品輸送のご相談はTOP GROUPへ"
      />
      <meta name="keywords" content="" />
      <title>TOP GROUP | ティーオーピーグループ</title>
      <link
        rel="shortcut icon"
        href="https://top-group.jp/web/images/41d8f90a1f8d9a1ef2e794f583ead0ea.jpg"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
        integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

export default WPMeta
