const Tel = () => {
  return (
    <div className="comBox">
      <div className="infoBox">
        <div className="lBox">
          <p className="title">
            人材総合サービス・通関サポート業務・商品輸送の
            <br className="sp" />
            ご相談はTOP GROUPへ
          </p>
        </div>
        <div className="rBox clearfix">
          <div className="tel">
            <a href="tel:0120886662" className="en">
              <img
                src="https://top-group.jp/web/wp-content/themes/TOP/img/common/icon01.png"
                width="41"
                alt="tel"
              />
              0120-88-6662
            </a>
          </div>
          <div className="btn">
            <a href="https://top-group.jp/contact">
              <span className="info">メールでのご相談はこちら</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tel
